import { defineNuxtPlugin } from '#app'
import { createVuetify } from 'vuetify'

const isDev = process.env.NODE_ENV === "development"
const options = JSON.parse('{"ssr":false,"treeshaking":true,"useVuetifyLabs":false}')

''

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify(options)
  nuxtApp.vueApp.use(vuetify)

  if (!process.server && isDev) {
    // eslint-disable-next-line no-console
    console.log('💚 Initialized Vuetify 3', vuetify)
  }

  return {
    provide: {
      vuetify
    }
  }
})
