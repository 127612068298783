import { default as _91id_93Cvp5WQbwSvMeta } from "/app/pages/cameras/[id].vue?macro=true";
import { default as indexjGb0wpToAvMeta } from "/app/pages/cameras/index.vue?macro=true";
import { default as index9VSGbdbhueMeta } from "/app/pages/evenements/[id]/index.vue?macro=true";
import { default as locationFhjLl6Cew1Meta } from "/app/pages/evenements/[id]/location.vue?macro=true";
import { default as recommended_45pathrMGJc3l4IZMeta } from "/app/pages/evenements/[id]/recommended-path.vue?macro=true";
import { default as indexDwa19ZhWx5Meta } from "/app/pages/evenements/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as index92NlF7s2UrMeta } from "/app/pages/its/index.vue?macro=true";
import { default as create1GISEiP1YYMeta } from "/app/pages/outils-map/create.vue?macro=true";
import { default as indexLatTVxMulZMeta } from "/app/pages/outils-map/index.vue?macro=true";
import { default as indexUcfewhgcuvMeta } from "/app/pages/pbr/index.vue?macro=true";
import { default as _91id_93REvBbJ3yqNMeta } from "/app/pages/peages/[id].vue?macro=true";
import { default as indexJSwbpVxLx1Meta } from "/app/pages/peages/index.vue?macro=true";
import { default as indexFCLlgSmw3WMeta } from "/app/pages/reglages/index.vue?macro=true";
import { default as indexfh1oylLJDnMeta } from "/app/pages/simulateur-bus/index.vue?macro=true";
import { default as indexDqa6faoT7pMeta } from "/app/pages/simulateur-events/index.vue?macro=true";
import { default as indexvkrDGNJSBuMeta } from "/app/pages/simulateur-pbr/index.vue?macro=true";
import { default as _91id_93DMCyb34rwSMeta } from "/app/pages/simulateur-peages/[id].vue?macro=true";
import { default as indexheNBeYU4RSMeta } from "/app/pages/simulateur-peages/index.vue?macro=true";
import { default as indexUVI39vtgp1Meta } from "/app/pages/test/index.vue?macro=true";
import { default as _91id_934ToV52ZNVEMeta } from "/app/pages/ubrs/[id].vue?macro=true";
import { default as indexdDEMtxQedmMeta } from "/app/pages/ubrs/index.vue?macro=true";
import { default as indextioKgWeG1NMeta } from "/app/pages/voies-de-bus/index.vue?macro=true";
export default [
  {
    name: _91id_93Cvp5WQbwSvMeta?.name ?? "cameras-id",
    path: _91id_93Cvp5WQbwSvMeta?.path ?? "/cameras/:id()",
    meta: _91id_93Cvp5WQbwSvMeta || {},
    alias: _91id_93Cvp5WQbwSvMeta?.alias || [],
    redirect: _91id_93Cvp5WQbwSvMeta?.redirect,
    component: () => import("/app/pages/cameras/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjGb0wpToAvMeta?.name ?? "cameras",
    path: indexjGb0wpToAvMeta?.path ?? "/cameras",
    meta: indexjGb0wpToAvMeta || {},
    alias: indexjGb0wpToAvMeta?.alias || [],
    redirect: indexjGb0wpToAvMeta?.redirect,
    component: () => import("/app/pages/cameras/index.vue").then(m => m.default || m)
  },
  {
    name: index9VSGbdbhueMeta?.name ?? "evenements-id",
    path: index9VSGbdbhueMeta?.path ?? "/evenements/:id()",
    meta: index9VSGbdbhueMeta || {},
    alias: index9VSGbdbhueMeta?.alias || [],
    redirect: index9VSGbdbhueMeta?.redirect,
    component: () => import("/app/pages/evenements/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: locationFhjLl6Cew1Meta?.name ?? "evenements-id-location",
    path: locationFhjLl6Cew1Meta?.path ?? "/evenements/:id()/location",
    meta: locationFhjLl6Cew1Meta || {},
    alias: locationFhjLl6Cew1Meta?.alias || [],
    redirect: locationFhjLl6Cew1Meta?.redirect,
    component: () => import("/app/pages/evenements/[id]/location.vue").then(m => m.default || m)
  },
  {
    name: recommended_45pathrMGJc3l4IZMeta?.name ?? "evenements-id-recommended-path",
    path: recommended_45pathrMGJc3l4IZMeta?.path ?? "/evenements/:id()/recommended-path",
    meta: recommended_45pathrMGJc3l4IZMeta || {},
    alias: recommended_45pathrMGJc3l4IZMeta?.alias || [],
    redirect: recommended_45pathrMGJc3l4IZMeta?.redirect,
    component: () => import("/app/pages/evenements/[id]/recommended-path.vue").then(m => m.default || m)
  },
  {
    name: indexDwa19ZhWx5Meta?.name ?? "evenements",
    path: indexDwa19ZhWx5Meta?.path ?? "/evenements",
    meta: indexDwa19ZhWx5Meta || {},
    alias: indexDwa19ZhWx5Meta?.alias || [],
    redirect: indexDwa19ZhWx5Meta?.redirect,
    component: () => import("/app/pages/evenements/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index92NlF7s2UrMeta?.name ?? "its",
    path: index92NlF7s2UrMeta?.path ?? "/its",
    meta: index92NlF7s2UrMeta || {},
    alias: index92NlF7s2UrMeta?.alias || [],
    redirect: index92NlF7s2UrMeta?.redirect,
    component: () => import("/app/pages/its/index.vue").then(m => m.default || m)
  },
  {
    name: create1GISEiP1YYMeta?.name ?? "outils-map-create",
    path: create1GISEiP1YYMeta?.path ?? "/outils-map/create",
    meta: create1GISEiP1YYMeta || {},
    alias: create1GISEiP1YYMeta?.alias || [],
    redirect: create1GISEiP1YYMeta?.redirect,
    component: () => import("/app/pages/outils-map/create.vue").then(m => m.default || m)
  },
  {
    name: indexLatTVxMulZMeta?.name ?? "outils-map",
    path: indexLatTVxMulZMeta?.path ?? "/outils-map",
    meta: indexLatTVxMulZMeta || {},
    alias: indexLatTVxMulZMeta?.alias || [],
    redirect: indexLatTVxMulZMeta?.redirect,
    component: () => import("/app/pages/outils-map/index.vue").then(m => m.default || m)
  },
  {
    name: indexUcfewhgcuvMeta?.name ?? "pbr",
    path: indexUcfewhgcuvMeta?.path ?? "/pbr",
    meta: indexUcfewhgcuvMeta || {},
    alias: indexUcfewhgcuvMeta?.alias || [],
    redirect: indexUcfewhgcuvMeta?.redirect,
    component: () => import("/app/pages/pbr/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93REvBbJ3yqNMeta?.name ?? "peages-id",
    path: _91id_93REvBbJ3yqNMeta?.path ?? "/peages/:id()",
    meta: _91id_93REvBbJ3yqNMeta || {},
    alias: _91id_93REvBbJ3yqNMeta?.alias || [],
    redirect: _91id_93REvBbJ3yqNMeta?.redirect,
    component: () => import("/app/pages/peages/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJSwbpVxLx1Meta?.name ?? "peages",
    path: indexJSwbpVxLx1Meta?.path ?? "/peages",
    meta: indexJSwbpVxLx1Meta || {},
    alias: indexJSwbpVxLx1Meta?.alias || [],
    redirect: indexJSwbpVxLx1Meta?.redirect,
    component: () => import("/app/pages/peages/index.vue").then(m => m.default || m)
  },
  {
    name: indexFCLlgSmw3WMeta?.name ?? "reglages",
    path: indexFCLlgSmw3WMeta?.path ?? "/reglages",
    meta: indexFCLlgSmw3WMeta || {},
    alias: indexFCLlgSmw3WMeta?.alias || [],
    redirect: indexFCLlgSmw3WMeta?.redirect,
    component: () => import("/app/pages/reglages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfh1oylLJDnMeta?.name ?? "simulateur-bus",
    path: indexfh1oylLJDnMeta?.path ?? "/simulateur-bus",
    meta: indexfh1oylLJDnMeta || {},
    alias: indexfh1oylLJDnMeta?.alias || [],
    redirect: indexfh1oylLJDnMeta?.redirect,
    component: () => import("/app/pages/simulateur-bus/index.vue").then(m => m.default || m)
  },
  {
    name: indexDqa6faoT7pMeta?.name ?? "simulateur-events",
    path: indexDqa6faoT7pMeta?.path ?? "/simulateur-events",
    meta: indexDqa6faoT7pMeta || {},
    alias: indexDqa6faoT7pMeta?.alias || [],
    redirect: indexDqa6faoT7pMeta?.redirect,
    component: () => import("/app/pages/simulateur-events/index.vue").then(m => m.default || m)
  },
  {
    name: indexvkrDGNJSBuMeta?.name ?? "simulateur-pbr",
    path: indexvkrDGNJSBuMeta?.path ?? "/simulateur-pbr",
    meta: indexvkrDGNJSBuMeta || {},
    alias: indexvkrDGNJSBuMeta?.alias || [],
    redirect: indexvkrDGNJSBuMeta?.redirect,
    component: () => import("/app/pages/simulateur-pbr/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DMCyb34rwSMeta?.name ?? "simulateur-peages-id",
    path: _91id_93DMCyb34rwSMeta?.path ?? "/simulateur-peages/:id()",
    meta: _91id_93DMCyb34rwSMeta || {},
    alias: _91id_93DMCyb34rwSMeta?.alias || [],
    redirect: _91id_93DMCyb34rwSMeta?.redirect,
    component: () => import("/app/pages/simulateur-peages/[id].vue").then(m => m.default || m)
  },
  {
    name: indexheNBeYU4RSMeta?.name ?? "simulateur-peages",
    path: indexheNBeYU4RSMeta?.path ?? "/simulateur-peages",
    meta: indexheNBeYU4RSMeta || {},
    alias: indexheNBeYU4RSMeta?.alias || [],
    redirect: indexheNBeYU4RSMeta?.redirect,
    component: () => import("/app/pages/simulateur-peages/index.vue").then(m => m.default || m)
  },
  {
    name: indexUVI39vtgp1Meta?.name ?? "test",
    path: indexUVI39vtgp1Meta?.path ?? "/test",
    meta: indexUVI39vtgp1Meta || {},
    alias: indexUVI39vtgp1Meta?.alias || [],
    redirect: indexUVI39vtgp1Meta?.redirect,
    component: () => import("/app/pages/test/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_934ToV52ZNVEMeta?.name ?? "ubrs-id",
    path: _91id_934ToV52ZNVEMeta?.path ?? "/ubrs/:id()",
    meta: _91id_934ToV52ZNVEMeta || {},
    alias: _91id_934ToV52ZNVEMeta?.alias || [],
    redirect: _91id_934ToV52ZNVEMeta?.redirect,
    component: () => import("/app/pages/ubrs/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdDEMtxQedmMeta?.name ?? "ubrs",
    path: indexdDEMtxQedmMeta?.path ?? "/ubrs",
    meta: indexdDEMtxQedmMeta || {},
    alias: indexdDEMtxQedmMeta?.alias || [],
    redirect: indexdDEMtxQedmMeta?.redirect,
    component: () => import("/app/pages/ubrs/index.vue").then(m => m.default || m)
  },
  {
    name: indextioKgWeG1NMeta?.name ?? "voies-de-bus",
    path: indextioKgWeG1NMeta?.path ?? "/voies-de-bus",
    meta: indextioKgWeG1NMeta || {},
    alias: indextioKgWeG1NMeta?.alias || [],
    redirect: indextioKgWeG1NMeta?.redirect,
    component: () => import("/app/pages/voies-de-bus/index.vue").then(m => m.default || m)
  }
]